/** @format */

import httpFetch from 'src/util/httpFetch';
//联络相关
const custom = {
    name: 'custom',
    apis: {
		//custom列表
		customList(params){
            return httpFetch('post', `/api/custom/customList`,params);
        },
		//custom详情
		customShow(params){
			return httpFetch('post', `/api/custom/customShow`,params);
		},
		//custom创建
		customCreate(params){
			return httpFetch('post', `/api/custom/customCreate`,params);
		},
    }
};
export default custom;
