/*
 * action 类型
 */
export const config  = 'config';


/*
 * 存储
 */
export function store(value) {
	return { type: config, value:value }
}