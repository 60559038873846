/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//admin查询相关
const admin = {
    name: 'admin',
    apis: {
        adminUserList(params){
            return httpFetch('post', '/api/admin/adminUserList',params);
        },
        findUser(params){
            return httpFetch('get', '/api/user/userList',params);
        },
        findCoachCoach(params) {
            return httpFetch('get', '/api/admin/findCoachCoach',params);
        },
        coachPrizes(params){
            return httpFetch('get', '/api/admin/coachPrizes',params);
        },
        findHisStudent(param) {
            return httpFetch('get', '/api/admin/findHisStudent',param);
        },
        findHisCoach(param) {
            return httpFetch('get', '/api/admin/findHisCoach',param);
        },
        setCoachBenefited(param) {
            return httpFetch('post', '/api/admin/setCoachBenefited',param);
        },
        setCoachIsConnected(param) {
            return httpFetch('post', '/api/admin/setCoachIsConnected',param);
        },
        setCoachIsCooperate(param) {
            return httpFetch('post', '/api/admin/setCoachIsCooperate',param);
        },
        setCoachIsAllowCustomize(param) {
            return httpFetch('post', '/api/admin/setCoachIsAllowCustomize',param);
        },
        //有可提现余额通知
        prizeMoneyGetInform(param) {
            return httpFetch('post', '/api/inform/prizeMoneyGetInform',param);
        },
        //考场更新消息推送
        examUpdateInform(param) {
            return httpFetch('post', '/api/inform/examUpdateInform',param);
        },
        //公共公告通知发布（内部显示）
        publicInformEdit(param) {
            return httpFetch('post', '/api/inform/publicInformEdit',param);
        },
        //个人站内通知推送
        internalInformPost(param) {
            return httpFetch('post', '/api/inform/internalInformPost',param);
        },
        //公众号通知推送
        userGzhInform(param) {
            return httpFetch('post', '/api/inform/userGzhInform',param);
        },
        //教练周（月）收益播报
        prizeTotalMoneyInform(param) {
            return httpFetch('post', '/api/inform/prizeTotalMoneyInform',param);
        },
		//流水-利润图标
		orderCountInit(param) {
			return httpFetch('post', '/api/count/orderCountInit',param);
		},
        //流水-利润图标
        moneyCount(param) {
            return httpFetch('post', '/api/count/moneyCount',param);
        },
        //流水-利润图标
        userRegisterCount(param) {
            return httpFetch('get', '/api/count/userRegisterCount',param);
        },
        //用户更换考场合集或者视频
        changeVideo(param) {
            return httpFetch('post', '/api/admin/changeVideo',param);
        },
        //用户信息修改
        userInfoEdit(params){
            return httpFetch('post', '/api/admin/userInfoEdit',params);
        },
        //用户自定义价格查询
        userCustomizeInfo(params){
            return httpFetch('get', '/api/admin/userCustomizeInfo',params);
        },
        //用户自定义价格编辑
        userCustomizeEdit(params){
            return httpFetch('post', '/api/admin/userCustomizeEdit',params);
        },
        //用户自定义价格编辑
        userCountAdmin(params){
            return httpFetch('get', '/api/admin/userCountAdmin',params);
        },
        //城市代理教练所属城市设置
        agentCitySet(params){
            return httpFetch('post', '/api/admin/agentCitySet',params);
        },
        /**
         * 奖品单条查询查询
         * @returns {*}
         */
        prizeGet(params){
            return httpFetch('get', `/api/admin/prizeGet`,params);
        },
        //废弃奖品
        prizeDestroy(params){
            return httpFetch('post', '/api/admin/prizeDestroy',params);
        },
        //批量设置为已发放返点
        batchBeenPaid(params){
            return httpFetch('post', '/api/admin/batchBeenPaid',params);
        },
        //批量更新所有考场视频时间
        videoDateUpdate(){
            return httpFetch('post', '/api/admin/videoDateUpdate');
        },
		//批量更新所有考场视频时间
		moveToVip(){
			return httpFetch('post', '/api/admin/moveToVip');
		},
		//检测单选题被错误设置为多选题的情况
		checkSubjectOption(){
			return httpFetch('post', '/api/admin/checkSubjectOption');
		},
		//isGetMoneyRollback
		isGetMoneyRollback(){
			return httpFetch('post', '/api/admin/isGetMoneyRollback');
		},
		//寻找10个教练
		find10Coach(params){
			return httpFetch('post', '/api/admin/find10Coach',params);
		},
		//查询二级返点
		checkSecondPrize(params){
			return httpFetch('post', '/api/admin/checkSecondPrize',params);
		},
		//查询二级返点
		firstDueRollBack(params){
			return httpFetch('post', '/api/admin/firstDueRollBack',params);
		},
		// 用户布尔值设置
		userBooleanSet(params){
			return httpFetch('post', '/api/admin/userBooleanSet',params);
		},
		// 帮用户设置所属考场
		userExamEdit(params){
			return httpFetch('post', '/api/admin/userExamEdit',params);
		},
		//	通过订单回溯教练的exam
		userExamSource(params){
			return httpFetch('post', '/api/admin/userExamSource',params);
		},

    }
};
export default admin;
