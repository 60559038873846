/** @format */

import httpFetch from 'src/util/httpFetch';
//版本相关
const version = {
    name: 'version',
    apis: {
        /**
         * 视频列表获取
         * @returns {*}
         */
        versionList() {
            return httpFetch('get', '/api/version');
        },
        /**
         * 设置版本
         * version 版本号
         */
         setVersion(params = { version: undefined }) {
            return httpFetch('post', `/api/version/setVersion`, params);
        }
    }
};
export default version;
