/** @format */
/**
 * 路由配置对象化
 * @return {array}
 */
 import Loadable from 'react-loadable';
 import Loading from 'src/Components/Public/Loading/index';
 const routes = [
     {
         path: '/login', //登录
         component: Loadable({ loader: () => import('src/Components/Login/index'), loading: Loading })
     },
     {
         path: '/', //主页
         component: Loadable({ loader: () => import('src/Components/Main/index'), loading: Loading }),
         routes: [
             {
                 exact: true,
                 path: '/', //demo
                 component: Loadable({ loader: () => import('src/Components/Welcome/index'), loading: Loading })
             },
             {
                 exact: true,
                 path: '/demo', //demo
                 component: Loadable({ loader: () => import('src/Components/Public/CitySelectorFlush/index'), loading: Loading })
             },
             {
                 exact: true,
                 path: '/video', //视频管理-科目一
                 component: Loadable({ loader: () => import('src/Components/Video'), loading: Loading })
             },
             {
                 exact: true,
                 path: '/user', //用户组管理
                 component: Loadable({ loader: () => import('src/Components/User/index'), loading: Loading })
             },
			 {
				 exact: true,
				 path: '/payOrder', //商家支付管理
				 component: Loadable({ loader: () => import('src/Components/PayOrder/index'), loading: Loading })
			 },
             {
                 exact: true,
                 path: '/examroom', //考场管理
                 component: Loadable({ loader: () => import('src/Components/ExamRoom/index'), loading: Loading })
             },
			 {
				 exact: true,
				 path: '/item', //权益购买
				 component: Loadable({ loader: () => import('src/Components/Item/index'), loading: Loading })
			 },
             {
                 exact: true,
                 path: '/lightExam', //灯光管理
                 component: Loadable({ loader: () => import('src/Components/lightExam/index'), loading: Loading })
             },
             {
                 exact: true,
                 path: '/money', //提现审核
                 component: Loadable({ loader: () => import('src/Components/CashAudit/index'), loading: Loading })
             },
             {
                 exact: true,
                 path: '/firstPrize', //首单
                 component: Loadable({ loader: () => import('src/Components/FirstPrize/index'), loading: Loading })
             },
             {
                 exact: true,
                 path: '/comment', //评论审核
                 component: Loadable({ loader: () => import('src/Components/CommentAudit'), loading: Loading })
             },
             {
                 exact: true,
                 path: '/inform', //通知发布
                 component: Loadable({ loader: () => import('src/Components/InformPost'), loading: Loading })
             },
             {
                 exact: true,
                 path: '/count', //通知发布
                 component: Loadable({ loader: () => import('src/Components/Count'), loading: Loading })
             },
             {
                 exact: true,
                 path: '/subject', //科目一四的题目
                 component: Loadable({ loader: () => import('src/Components/Subject'), loading: Loading })
             },
             {
                 exact: true,
                 path: '/pool', //科目一四题库
                 component: Loadable({ loader: () => import('src/Components/Pool'), loading: Loading })
             },
			 {
				 exact: true,
				 path: '/subjectComment', //科目评论
				 component: Loadable({ loader: () => import('src/Components/SubjectComment'), loading: Loading })
			 },
             {
                 exact: true,
                 path: '/bill', //账单
                 component: Loadable({ loader: () => import('src/Components/Bill'), loading: Loading })
             },
             {
                 exact: true,
                 path: '/benefit', //收益计算总结
                 component: Loadable({ loader: () => import('src/Components/BenefitCount/index'), loading: Loading })
             },
             {
                exact: true,
                path: '/miniProgram', //小程序配置
                component: Loadable({ loader: () => import('src/Components/MiniProgram/index'), loading: Loading })
            },
			 {
				 exact: true,
				 path: '/offer', //赠与管理
				 component: Loadable({ loader: () => import('src/Components/Offer/index'), loading: Loading })
			 },
			 {
				 exact: true,
				 path: '/coupon', //优惠券
				 component: Loadable({ loader: () => import('src/Components/Coupon'), loading: Loading })
			 },
			 {
				 exact: true,
				 path: '/visit', //查看视频
				 component: Loadable({ loader: () => import('src/Components/Visit'), loading: Loading })
			 },
			 {
				 exact: true,
				 path: '/custom', //待处理客户
				 component: Loadable({ loader: () => import('src/Components/Custom'), loading: Loading })
			 },
			 {
				 exact: true,
				 path: '/pack', //包裹
				 component: Loadable({ loader: () => import('src/Components/Pack'), loading: Loading })
			 },
			 {
				 exact: true,
				 path: '/coachAd', //包裹
				 component: Loadable({ loader: () => import('src/Components/CoachAd'), loading: Loading })
			 },
         ]
     }
 ];
 export default routes;
