/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//账单相关
const lightExam = {
    name: 'lightExam',
    apis: {
        /**
         * 获取全量音频列表
         * @returns {*}
         */
        getLightList() {
            return httpFetch('get', `/api/lightExam/lightList`);
        },
        /**
         * 保存单条灯光素材到素材库
         * @returns {*}
         */
        saveLightAudio(data) {
            return httpFetch('post', `/api/lightExam/saveLightAudio`, data);
        },
        /**
         * 删除素材库的单条灯光素材
         * @returns {*}
         */
         deleteLightAudio(data) {
            return httpFetch('delete', `/api/lightExam/deleteLightAudio`, data);
        },
        /**
         * 删除音频
         * @param id
         * @returns {*}
         */
        deleteLightById(id) {
            return httpFetch('delete', `/api/lightExam/${id}`);
        },
        /**
         * 创建或更新考场灯光
         * @returns {*}
         */
        postLightExam(params) {
            return httpFetch('post', `/api/lightExam`, params);
        },

        /**
         * 保存灯光集合到考场 灯光考试
         * @returns {*}
         */
        saveLightExam(params) {
            return httpFetch('post', `/api/lightExam/saveLightExam`, params);
        },
        
        /**
         * 保存灯光集合到考场 灯光考试
         * @returns {*}
         */
         getLightExams(params) {
            return httpFetch('post', `/api/lightExam/getLightExams`, params);
        },
        /**
         * 获取灯光枚举
         * @returns {*}
         */
         getLightEnum() {
            return httpFetch('get', `/api/lightExam/getLightEnum`);
        }
    }
};
export default lightExam;
