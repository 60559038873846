/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//账单相关
const bill = {
    name: 'bill',
    apis: {
        /**
         * 账单列表
         * @param params
         * @returns {*}
         */
        billList(params){
            return httpFetch('get', `/api/bill`,params);
        },
        /**
         * 账单详情
         * @param id
         * @returns {*}
         */
        billShow(id){
            return httpFetch('get', `/api/bill/${id}`);
        },
        /**
         * 创建订单
         * @returns {*}
         */
        createBill(params){
            return httpFetch('post', `/api/bill`,params);
        },
        /**
         * 对应关系
         */
        mapList(){
            return httpFetch('get', `/api/bill/mapList`);
        },
    }
};
export default bill;
