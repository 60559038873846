// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/_css-loader@6.8.1@css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/_css-loader@6.8.1@css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/** @format */\n.localIdentName\\=src-Components-Public-Loading-css---init---ijGbu {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/Components/Public/Loading/css.less"],"names":[],"mappings":"AAAA,aAAa;AAEb;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;AAAJ","sourcesContent":["/** @format */\n\n.init {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"init": "localIdentName=src-Components-Public-Loading-css---init---ijGbu"
};
export default ___CSS_LOADER_EXPORT___;
