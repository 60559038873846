/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//视频相关
const prize = {
    name: 'prize',
    apis: {
        /**
         * 当前用户奖品列表查询
         * @returns {*}
         */
        prizeUserList(params){
            return httpFetch('get', `/api/prize`,params);
        },
        /**
         * 奖品列表查询（现金券）
         * @returns {*}
         */
        prizeList(params){
            return httpFetch('get', ` /api/prize/indexList`,params);
        },
        /**
         * 奖品兑换申请
         * @param params
         */
        cashPrize(params){
            return httpFetch('post', `/api/prize/cashPrize`,params);
        },
        /**
         * 奖品提现检测
         * @param params
         */
        cashAudit(params){
            return httpFetch('post', `/api/prize/cashAudit`,params);
        },
        /**
         * 奖品提现通过
         * @param params
         */
        batchCrashPass(params){
            return httpFetch('post', `/api/prize/batchCrashPass`,params);
        },
        /**
         * 奖品提现通过
         * @param params
         */
		prizeCrash(params){
            return httpFetch('post', `/api/prize/prizeCrash`,params);
        },

    }
};
export default prize;
