/** @format */

import httpFetch from 'src/util/httpFetch';
//退款相关
const refundOrder = {
    name: 'refundOrder',
    apis: {
		/**
		 * 退款
		 */
		orderRefund(params){
			return httpFetch('post', `/api/refundOrder/orderRefund`,params);
		},
    }
};
export default refundOrder;
