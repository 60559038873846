/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//评论相关
const subjectComment = {
    name: 'subjectComment',
    apis: {
        /**
         * 从爬的数据转到真实的表中。
         * @param params
         * @returns {*}
         */
        subjectCommentList(params) {
            return httpFetch('post', '/api/subjectComment/adminCommentList', params);
        },
        /**
         * 从爬的数据转到真实的表中。
         * @param params
         * @returns {*}
         */
        subjectCommentDelete(params) {
            return httpFetch('post', '/api/subjectComment/subjectCommentDelete', params);
        },
        /**
         * 从爬的数据转到真实的表中。
         * @param params
         * @returns {*}
         */
        subjectCommentPass(params) {
            return httpFetch('post', '/api/subjectComment/subjectCommentPass', params);
        },
        /**
         * 从爬的数据转到真实的表中。
         * @param params
         * @returns {*}
         */
        moveComment(params) {
            return httpFetch('post', '/api/subjectComment/moveComment', params);
        },
    }
};
export default subjectComment;
