/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//视频相关
const user = {
    name: 'user',
    apis: {
		/**
		 * 用户自动登录
		 * @returns {*}
		 */
		autoLogin(params){
			return httpFetch('post', '/api/user/autoLogin',params);
		},
        /**
         * 登录
         * @param params
         * @account 账号。
         * @psw 密码
         */
        login(
            params = { account:'', psw:''}
        ) {
            return httpFetch('post', '/api/user/adminLogin', params);
        },
        /**
         * 微信用户登录
         * @param params
         * @returns {*}
         */
        wxLogin(
            params = { appId:'', appSecret:'',code:''}
        ){
            return httpFetch('post', '/api/user/login', params);
        },
        /**
         * 获取当前登录用户的信息
         * @returns {*}
         */
        userInfo(){
            return httpFetch('get', '/api/user');
        },
        /**
         *  用户登录状态检查
         * @returns {*}
         */
        userLoginState() {
            return httpFetch('get', '/api/user/loginCheck');
        },
        /**
         * 登出
         */
        userLogout() {
            return httpFetch('get', '/api/user/logout');
        },
        /**
         * 登出
         */
        coachMessageVerify(params = {phoneNum:13160670343}) {
            return httpFetch('post', '/api/user/coachMessageVerify',params);
        },
        /**
         * 教练须知
         * @returns {*}
         */
        coachShouldKnow() {
            return httpFetch('get', '/api/user/coachShouldKnow');
        },
        /**
         * 搜索
         * @param params
         * @returns {*}
         */
        search(params = {content:undefined,searchType:undefined}){
            return httpFetch('get', '/api/user/search',params);
        },
        /**
         * 设置为种子用户
         * @param params
         * @returns {*}
         */
        setSeedUser(params = {userId:undefined}){
            return httpFetch('post', '/api/user/setSeedUser',params);
        },
        /**
         * 设置为教练用户
         * @param params
         * @returns {*}
         */
        setCoachUser(params = {userId:undefined}){
            return httpFetch('post', '/api/user/setCoachUser',params);
        },
        /**
         * 设置为学员用户
         * @param params
         * @returns {*}
         */
        setStudentUser(params = {userId:undefined}){
            return httpFetch('post', '/api/user/setStudentUser',params);
        },
        /**
         * 设置为学员用户
         * @param params
         * @returns {*}
         */
        setVipUser(params = {userId:undefined}){
            return httpFetch('post', '/api/user/setVipUser',params);
        },
        /**
         * 公众号token获取
         * @returns {*}
         */
        gzhInformToken(){
            return httpFetch('get', '/api/inform/gzhinform');
        },
        /**
         * 用户返点信息
         */
        userCount(params){
            return httpFetch('get', `/api/user/userCount`,params);
        },
    }
};
export default user;
