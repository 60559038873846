/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//评论相关
const visit = {
    name: 'visit',
    apis: {
		/**
		 * 访客list
		 * @param params
		 * @returns {*}
		 */
		visitList(params){
			return httpFetch('post', `/api/visit/visitList`,params);
		},
        /**
         * 评论发布
         * @param params
         * @returns {*}
         */
        createVisit(params){
            return httpFetch('post', `/api/visit/createVisit`,params);
        },
    }
};
export default visit;
