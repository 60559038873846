/** @format */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter , Route,Switch } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { message } from 'antd';
import api from 'src/App.api';
import Loading from 'src/Components/Public/Loading';
import webapp from 'src/util/webapp';
import { EnumeAction, UserAction, HistoryAction } from 'src/Reducers/action';
import routes from 'src/routes';
import styles from './App.less';
const history = createBrowserHistory();
class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading:false
		}
	}
	componentWillMount() {
		this.props.dispatch(HistoryAction.store(history));
	}
	componentDidMount() {
		if(webapp.isWeiXin()){
			this.setState({loading:true})
			return
		}
		this.enumeGet();
		this.checkUserLoginState();
		// console.log(this.props.history)
	}
	/**
	 * 枚举获取
	 */
	enumeGet = () => {
		api.Enume.EnumeMap().then((res) => {
			let data = res.data;
			for (let i in data) {
				let arrName = i + `Arr`;
				data[arrName] = webapp.mapToArr(data[i]);
			}
			this.props.dispatch(EnumeAction.store(res.data));
		});
	};
	checkUserLoginState = () => {
		//检测登录状态
		api.user
			.autoLogin({})
			.then((res) => {
				let userData = res.data,
					token = res.token;
				const newUserData = JSON.stringify(userData);
				window.localStorage.setItem('userData', newUserData);
				webapp.cookieSet('jwt_token', token, 7);
			})
			.catch((res) => {
				if (res && res.msg) {
					this.props.history.push('/login')
				}
			});
	};
	render() {
		if(this.state.loading) return <Loading/>;
		if(this.props.Enume.loading) return null;
		return (
			<ConnectedRouter className={styles.App} history={history}>
				<Switch>
					{routes.map((route, i) => {
						return (
							<Route
								key={route.path}
								path={route.path}
								render={(props) => <route.component {...props} routes={route.routes} />}
							/>
						);
					})}
				</Switch>
			</ConnectedRouter>
		);
	}
}
function select(state) {
	return {
		history: state.history,
		Enume: state.Enume,
		User: state.User
	};
}

export default connect(select)(App);
