/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//视频相关
const order = {
    name: 'order',
    apis: {
        /**
         * 订单详情
         * @param orderId
         * @returns {*}
         */
        orderShow(orderId){
            return httpFetch('get', `/api/order/${orderId}`);
        },
        /**
         * 创建订单
         * @returns {*}
         */
        createOrder(){
            return httpFetch('post', `/api/order`);
        },
        /**
         * 学生支付订单后，通知教练来平台领取奖励.
         */
        afterPayMessage(params){
            return httpFetch('post', `/api/order/afterPayMessage`,params);
        },
		/**
		 * 退款
		 */
		orderRefund(params){
			return httpFetch('post', `/api/order/orderRefund`,params);
		},
		/**
		 * 用户支付成功的订单.(含退款及退款中)
		 */
		userOrderList(params){
			return httpFetch('post', `/api/order/userOrderList`,params);
		},
    }
};
export default order;
