/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//评论相关
const comment = {
    name: 'comment',
    apis: {
        /**
         * 评论列表获取
         * @param params
         * @returns {*}
         */
        commentList(params) {
            return httpFetch('get', '/api/comment', params);
        },
        /**
         * 评论详情
         */
        videoShow(videoId) {
            return httpFetch('get', `/api/video/${videoId}`);
        },
		videoShow2(params) {
			return httpFetch('post', '/api/video/videoShow2', params);
		},
		/**
		 * 发布公共评论
		 * @param params
		 * @returns {*}
		 */
		createPublicComment(params) {
			return httpFetch('post', '/api/comment/createPublicComment', params);
		},
        /**
         * 评论发布
         * @param params
         * @returns {*}
         */
        commentPost(params) {
            return httpFetch('post', '/api/comment', params);
        },
        /**
         * 获取可评论的用户
         * @returns {*}
         */
        commentUserList(params) {
            return httpFetch('post', `/api/comment/commentUser`,params);
        },
        /**
         * 通过评论
         */
        auditPass(params) {
            return httpFetch('post', `/api/comment/auditPass`, params);
        },

        /**
         * 删除评论
         */
        commentDelete(commentId) {
            return httpFetch('delete', `/api/comment/${commentId}`);
        }
    }
};
export default comment;
