/** @format */

import httpFetch from 'src/util/httpFetch';
//余额相关
const userScoreApi = {
    name: 'userScore',
    apis: {
        /**
         * 余额查询
         * @returns {*}
         */
		adminUserScoreGet(params){
            return httpFetch('post', `/api/userScore/adminUserScoreGet`,params);
        },
		/**
		 * 管理员溯源以前的prize至userScore中
		 * @returns {*}
		 */
		scoreScoreSource(params){
			return httpFetch('post', `/api/userScore/scoreScoreSource`,params);
		},
    }
};
export default userScoreApi;
