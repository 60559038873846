/** @format */

import httpFetch from 'src/util/httpFetch';
//用户个人配置相关
const userConfig = {
    name: 'userConfig',
    apis: {
        /**
         *  管理员设置用户某些配置
         * @param params
         * @returns {*}
         */
		batchUpdateUserConfig(params) {
            return httpFetch('post', '/api/userConfig/batchUpdateUserConfig', params);
        }
    }
};
export default userConfig;
