/** @format */

// import fetch from 'dva/fetch';
import { notification } from 'antd';
import hash from 'hash.js';
import webapp from 'src/util/webapp';
// import { isAntdPro } from './utils';
const homepage = '';

const codeMessage = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。'
};

/**
 * 把对象数据 查询字符串
 * <pre>
 *     使用于:
 *      get请求时,把对象传话成字符串,拼接到url
 *      post请求时,非json方式的content-type
 * </pre>
 * @param obj
 * @returns {string}
 */
const queryToString = (obj) => {
    if (obj === undefined) {
        return '';
    }
    let query = '';
    for (let i in obj) {
        if (obj[i] || obj[i] === 0) {
            query = `${query}&${i}=${obj[i]}`;
        }
    }
    query = query.substr(1);
    query = `?${query}`;
    return query;
};

const checkStatus = (response, url, config) => {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    if (response.status === 403 && !config.noMore) {
        // 第一次请求需要拿到token，所以403时重新再请求一次
        const method = config.method;
        const params = config.body;
        //加一个标识，否则会无限请求
        config.noMore = true;
        return httpFetch(method, url, params, config);
    }
    const errortext = codeMessage[response.status] || response.statusText;
    if (process.env.NODE_ENV === 'development') {
        //开发环境 。production则是生产环境
        notification.error({
            message: `请求错误 ${response.status}: ${response.url}`,
            description: errortext
        });
    }
    const error = new Error(errortext);
    error.name = response.status;
    error.response = response;
    throw error;
};

const cachedSave = (response, hashcode) => {
    /**
     * Clone a response data and store it in sessionStorage
     * Does not support data other than json, Cache only json
     */
    try {
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.match(/application\/json/i)) {
            // All data is saved as text
            response
                .clone()
                .text()
                .then((content) => {
                    sessionStorage.setItem(hashcode, content);
                    sessionStorage.setItem(`${hashcode}:timestamp`, Date.now());
                });
        }
        return response;
    } catch (e) {
        return response;
    }
};

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
function request(
    url,
    options = {
        // expirys: true // isAntdPro(),
    }
) {
    /**
     * Produce fingerprints based on url and parameters
     * Maybe url has the same parameters
     */
    const fingerprint = url + (options.body ? JSON.stringify(options.body) : '');
    //hash化
    const hashcode = hash
        .sha256()
        .update(fingerprint)
        .digest('hex');

    const defaultOptions = {
        //cookie携带
        credentials: 'include'
    };
    const newOptions = { ...defaultOptions, ...options };
    const jwt_token = webapp.cookieGetExact('jwt_token');
    //加jwt_token
    if (jwt_token) {
        const AuthorizationValue = `Bearer ${jwt_token}`;
        // const AuthorizationValue = `${jwt_token}`;
        if (!newOptions.headers) {
            newOptions.headers = {
                Authorization: AuthorizationValue
            };
        } else {
            newOptions.headers.Authorization = AuthorizationValue;
        }
    }
    if (newOptions.method === 'POST' || newOptions.method === 'PUT' || newOptions.method === 'DELETE') {
        // const csrftoken = webapp.cookieGetExact('csrfToken');
        if (!(newOptions.body instanceof FormData)) {
            newOptions.headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
                // 'x-csrf-token': csrftoken,
                ...newOptions.headers
            };
            if (newOptions.body) {
                if (newOptions.body instanceof Object) {
                    newOptions.body = JSON.stringify(newOptions.body);
                }
            }
        } else {
            newOptions.credentials = 'omit';
            // newOptions.body is FormData
            newOptions.headers = {
                Accept: 'application/json',
                // 'x-csrf-token': csrftoken,
                // Access-Control-Allow-Origin:
                ...newOptions.headers
            };
        }
    }

    //延时，防止重复请求
    const expirys = 60;
    // options.expirys !== false, return the cache,
    const cached = sessionStorage.getItem(hashcode);
    const whenCached = sessionStorage.getItem(`${hashcode}:timestamp`);
    if (cached !== null && whenCached !== null) {
        const age = (Date.now() - whenCached) / 1000;
        if (age < expirys) {
            const response = new Response(new Blob([cached]));
            return response.json();
        }
        sessionStorage.removeItem(hashcode);
        sessionStorage.removeItem(`${hashcode}:timestamp`);
    }
    return fetch(url, newOptions)
        .then((response) => {
            return checkStatus(response, url, options);
        })
        .then((response) => {
            return cachedSave(response);
        })
        .then((response) => {
            if (response) {
                // DELETE and 204 do not return data by default
                // using .json will report an error.
                // newOptions.method === 'DELETE' ||  这句本来是text()
                if (response.status === 204) {
                    return response.text();
                }
                try {
                    return response.json();
                } catch (e) {
                    return response;
                }
            }
        })
        .catch((e) => {
            console.log(e);

            // const status = e.name;
            // if (status === 401) {
            //     // @HACK
            //     /* eslint-disable no-underscore-dangle */
            //     window.g_app._store.dispatch({
            //         type: 'login/logout',
            //     });
            //     return;
            // }
            // // environment should not be used
            // if (status === 403) {
            //     router.push('/exception/403');
            //     return;
            // }
            // if (status <= 504 && status >= 500) {
            //     router.push('/exception/500');
            //     return;
            // }
            // if (status >= 404 && status < 422) {
            //     router.push('/exception/404');
            // }
        });
}

function httpFetch(method, url, params, config) {
    url = homepage + url;
    config = config ? config : {};
    method = method.toUpperCase(); //转大写

    config.method = method;
    if (method === 'GET') {
        url = url + queryToString(params);
    } else {
        if (params) {
            config.body = params;
        }
    }
    return request(url, config)
        .then((result) => {
            if (result && (params instanceof FormData || result.op === 'suc')) {
                return Promise.resolve(result);
            } else {
                return Promise.reject(result);
            }
        })
        .catch((result) => {
            return Promise.reject(result);
        });
}
export default httpFetch;
