/** @format */

let config = {};

//图片比例
config.imageRate = {
    story: 335 / 210, //宽高比，只需要用宽度/rate即可得到需要显示的高度
    tag: 1
};
//数据的状态
config.feedState = {
    normal: 1, //正常
    deleted: 2, //删除
    forbidden: 3 //封禁
};
//默认图片
config.defaultImage = {
    avatar: '/public/defaultImage/avatar.jpg',
    userBg: '/public/defaultImage/userBg.jpg'
};
//图片外链
config.outsideLink = {
    link: 'https://qiniucdn.biguo.fanstag.com/', //七牛CDN加速域名,0413支持https
    basePiexl: '?imageView2/0/format/jpg/interlace/1/q/100', //基础处理，基本压缩，不失真，图片格式转为webp,渐进显示
    lowPiexl: '?imageView2/2/w/660/h/660/format/jpg/interlace/1/q/100' //低像素，330宽度，330高度，等比例缩放，图片格式转为webp，渐进显示
};
/**
 * 各种socket的订阅,格式：用户id + key
 * @type {{}}
 */
config.socketKey = {
    inform: 'inform',
    message: 'message'
};
config.step = {
    kemu1:1,
    kemu2:2,
    kemu3:3,
    kemu4:4,
};
config.prize = {
    //奖品状态
    state:{
        notActive:0,    //未激活
        normal:1,   //未申请
        cashing:2,  //兑换中
        checking:4, //检查中
        cashed:5,   //已兑换
        abolished:6,//废除
    },
    type:{
        coachToStudent:1,   //教练推荐学生
        coachToCoach:2,     //教练推荐教练
    },
    prizeMaxAmount:30*100,  //最大提现金额，30元
    //奖品的过期时间 14 天
    prizeExpiredTime: 86400 * 14,   //奖品有效期
    //最多同时兑换数
    maxCashingNumber:10,
    errorCode:{
        notOrderId:1,
    }
};
export default config;
