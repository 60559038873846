/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//账单相关
const subject = {
	name: 'subject',
	apis: {
		/**
		 * 题列表
		 * @param params
		 * @returns {*}
		 */
		subjectList(params){
			return httpFetch('get', `/api/subject`,params);
		},
		/**
		 * 题列表
		 * @param params
		 * @returns {*}
		 */
		managerSearchSubject(params){
			return httpFetch('post', `/api/subject/managerSearchSubject`,params);
		},

		/**
		 * 模糊查询
		 * @param params
		 * @returns {*}
		 */
		subjectSearch(params){
			return httpFetch('get', `/api/subject/subjectSearch`,params);
		},
		/**
		 * 题目详情
		 * @param id
		 * @returns {*}
		 */
		subjectShow(params){
			return httpFetch('post', `/api/subject/subjectShow`,params);
		},
		/**
		 * 题目入库
		 * @returns {*}
		 */
		dataJsonPost(params){
			return httpFetch('post', `/api/subject/createFromJson`,params);
		},
		/**
		 * 宝典题目入库
		 * @returns {*}
		 */
		baodiandataJsonPost(params){
			return httpFetch('post', `/api/subject/jikaoBaoDianIn`,params);
		},
		/**
		 * 一点通题目入库
		 * @returns {*}
		 */
		yiDianTongIn(params){
			return httpFetch('post', `/api/subject/yiDianTongIn`,params);
		},

		/**
		 * 题库list
		 * @param params
		 * @returns {*}
		 */
		poolList(params){
			return httpFetch('get', `/api/subject/poolList`,params);
		},
		poolDetail(params){
			return httpFetch('get', `/api/subject/poolDetail`,params);
		},
		createPool(params){
			return httpFetch('post', `/api/subject/createPool`,params);
		},
		poolDelete(params){
			return httpFetch('post', `/api/subject/poolDelete`,params);
		},
		subjectEdit(params){
			return httpFetch('post', `/api/subject/subjectEdit`,params);
		},
		subjectStateEdit(params){
			return httpFetch('post', `/api/subject/subjectStateEdit`,params);
		},
		/**
		 * 复制一道题
		 * @param params
		 * @returns {Promise<*>}
		 */
		subjectCopy(params){
			return httpFetch('post', `/api/subject/subjectCopy`,params);
		},
		/**
		 * 复制一道题
		 * @param params
		 * @returns {Promise<*>}
		 */
		subjectAutoCopy(params){
			return httpFetch('post', `/api/subject/subjectAutoCopy`,params);
		},
		/**
		 * 顺序题一键入库
		 * @param params
		 * @returns {Promise<*>}
		 */
		sequenceInPool(params){
			return httpFetch('post', `/api/subject/sequenceInPool`,params);
		},
		/**
		 * 题目技巧编辑
		 * @param params
		 * @returns {Promise<*>}
		 */
		subjectSkillEdit(params){
			return httpFetch('post', `/api/subject/subjectSkillEdit`,params);
		},
		/**
		 * 题目创建
		 * @param params
		 * @returns {Promise<*>}
		 */
		subjectCreate(params){
			return httpFetch('post', `/api/subject/subjectCreate`,params);
		},
	}
};
export default subject;
