/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//权益购买相关
const item = {
    name: 'item',
    apis: {
        /**
         * 权益列表
         * @param params
         * @returns {*}
         */
        itemList(params){
            return httpFetch('post', `/api/item/itemList`,params);
        },
		/**
		 * 权益列表
		 * @param params
		 * @returns {*}
		 */
		itemShow(params){
			return httpFetch('post', `/api/item/itemShow`,params);
		},
		/**
		 * 权益列表
		 * @param params
		 * @returns {*}
		 */
		itemEdit(params){
			return httpFetch('post', `/api/item/itemCreate`,params);
		},
    }
};
export default item;
