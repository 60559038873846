/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//otherPrize查询相关
const otherPrize = {
    name: 'otherPrize',
    apis: {
        /**
         * 奖品列表查询（现金券）
         * @returns {*}
         */
        otherPrizeList(params){
            return httpFetch('get', ` /api/otherPrize/otherPrizeList`,params);
        },
        //首单人员查询
        extraBenefitedUsers(params) {
            return httpFetch('get', '/api/otherPrize/extraBenefitedUsers',params);
        },
        //奖励详情
        extraBenefitedShow(id) {
            return httpFetch('get', `/api/otherPrize/${id}`);
        },
        //生成首单奖励
        extraBenefitedCreate(params) {
            return httpFetch('post', '/api/otherPrize/extraBenefitedCreate',params);
        },
        //审核
        extraBenefitedAudit(params) {
            return httpFetch('post', '/api/otherPrize/extraBenefitedAudit',params);
        },
    }
};
export default otherPrize;
