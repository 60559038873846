/** @format */

import httpFetch from 'src/util/httpFetch';
//商户支付相关
const read = {
    name: 'read',
    apis: {
		//read创建
		readCreate(params){
			return httpFetch('post', `/api/read/readCreate`,params);
		},
    }
};
export default read;
