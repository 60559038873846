/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//优惠券相关
const coupon = {
    name: 'coupon',
    apis: {
        /**
         * 获取所有优惠券的list
         * @param params
         * @returns {*}
         */
        couponList(params) {
            return httpFetch('post', '/api/coupon/list', params);
        },
        /**
         * 优惠券详情
         */
        couponShow(params) {
            return httpFetch('post', `/api/coupon/couponShow`,params);
        },
        /**
         * 优惠券发布
         * @param params
         * @returns {*}
         */
        couponPost(params) {
            return httpFetch('post', '/api/coupon/couponCreate', params);
        },
        /**
         * 优惠券批量上线
         * @param params
         * @returns {Promise<*>}
         */
        batchOnlineCoupon(params) {
            return httpFetch('post', '/api/coupon/batchOnlineCoupon', params);
        },
        /**
         * 批量下线已过使用期的优惠券
         * @param params
         * @returns {*}
         */
        updateCouponState(params) {
            return httpFetch('post', '/api/coupon/updateCouponState', params);
        },

        /**
         * 用户券领取
         * @param params
         * @returns {*}
         */
        offerCouponForUser(params) {
            return httpFetch('post', '/api/coupon/offerCouponForUser', params);
        },
    }
};
export default coupon;
