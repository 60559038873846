/*
 * action 类型
 */
export const User  = 'User';


/*
 * 存储
 */
export function store(value) {
    return { type: User, value:value }
}