/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//票相关
const userCoupon = {
    name: 'userCoupon',
    apis: {
        /**
         * 票详情
         */
        userCouponShow(params) {
            return httpFetch('post', `/api/userCoupon/userCouponShow`,params);
        },
        /**
         * 优惠券领取（测试）
         * @param params
         * @returns {*}
         */
        offerCouponForUser(params) {
            return httpFetch('post', '/api/userCoupon/offerCouponForUser', params);
        },
        /**
         * 授予某用户某优惠券
         * @param params
         * @returns {*}
         */
        managerOfferCoupon(params) {
            return httpFetch('post', '/api/userCoupon/managerOfferCoupon', params);
        },
        /**
         * 当前用户可用的优惠券
         * @param params
         * @returns {*}
         */
        userCouponList(params) {
            return httpFetch('post', '/api/userCoupon/userCouponList', params);
        },
        /**
         * 用户领取情况
         * @param params
         * @returns {Promise<*>}
         */
        userCouponListManager(params) {
            return httpFetch('post', '/api/userCoupon/userCouponListManager', params);
        },
    }
};
export default userCoupon;
