/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//打印级资源相关
const printResource = {
    name: 'printResource',
    apis: {
        /**
         * 视频列表获取
         * @param params
         * @returns {*}
         */
        printResourceList(params = { skip:0, limit:0 }) {
            return httpFetch('get', '/api/printResource', params);
        },
        /**
         * 详情
         */
        printResourceShow(params){
            return httpFetch('post', `/api/printResource/printResourceShow`,params);
        },

		printResourceCreate(params) {
            return httpFetch('post', '/api/printResource/printResourceCreate', params);
        },

		printResourceRouteImage(params) {
			return httpFetch('post', '/api/printResource/printResourceRouteImage', params);
		},
		printGroupCreate(params) {
			return httpFetch('post', '/api/printResource/printGroupCreate', params);
		},
		printGroupShow(params) {
			return httpFetch('post', '/api/printResource/printGroupShow', params);
		},
		printGroupList(params) {
			return httpFetch('post', '/api/printResource/printGroupList', params);
		},
		printResourceRouteImageBatch(params) {
			return httpFetch('post', '/api/printResource/printResourceRouteImageBatch', params);
		},
		packPrintResource(params) {
			return httpFetch('post', '/api/printResource/packPrintResource', params);
		},

    }
};
export default printResource;
