/** @format */

import httpFetch from 'src/util/httpFetch';
//联络相关
const pack = {
    name: 'pack',
    apis: {
		//pack列表
		packList(params){
            return httpFetch('post', `/api/pack/packList`,params);
        },
		//pack详情
		packShow(params){
			return httpFetch('post', `/api/pack/packShow`,params);
		},
		//pack创建
		packCreate(params){
			return httpFetch('post', `/api/pack/packCreate`,params);
		},
		//搜索包裹
		search(params){
			return httpFetch('post', `/api/pack/search`,params);
		},
    }
};
export default pack;
