/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//文案相关
const coachAd = {
    name: 'coachAd',
    apis: {
        /**
         * 权益列表
         * @param params
         * @returns {*}
         */
        coachAdList(params){
            return httpFetch('post', `/api/coachAd/coachAdList`,params);
        },
		/**
		 * 权益列表
		 * @param params
		 * @returns {*}
		 */
		coachAdShow(params){
			return httpFetch('post', `/api/coachAd/coachAdShow`,params);
		},
		/**
		 * 权益列表
		 * @param params
		 * @returns {*}
		 */
		coachAdEdit(params){
			return httpFetch('post', `/api/coachAd/coachAdCreate`,params);
		},
		/**
		 * coachAdEdit
		 * @param params
		 * @returns {*}
		 */
		coachAdRemove(params){
			return httpFetch('post', `/api/coachAd/coachAdRemove`,params);
		},
    }
};
export default coachAd;
