import * as action from './action'
const {history} = action;
const initState = {};
function history1(state = initState,action){
    switch (action.type){
        case history:
            return Object.assign({}, state, action.value);
        default:
            return state;
    }
}
export default history1;
