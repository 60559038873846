/*
 * action 类型
 */
export const history  = 'history';


/*
 * 存储
 */
export function store(value) {
    return { type: history, value:value }
}