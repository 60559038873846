/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//账单相关
const checkUpdate = {
    name: 'checkUpdate',
    apis: {
        /**
         * 检测更新系统
         * @param params
         * @returns {*}
         */
         check(params){
            return httpFetch('post', `/api/checkUpdate/check`,params);
        },
        /**
         * 自动获取考场数据
         * @param params
         * @returns {*}
         */
         autoAddExamIds(params){
            return httpFetch('post', `/api/checkUpdate/autoAddExamIds`,params);
        },
    }
};
export default checkUpdate;
