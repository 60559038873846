/** @format */

import httpFetch from 'src/util/httpFetch';
//商户支付相关
const payOrder = {
    name: 'payOrder',
    apis: {
        /**
         * 商户支付列表查询（现金券）
         * @returns {*}
         */
		adminPayOrderList(params){
            return httpFetch('post', ` /api/payOrder/adminPayOrderList`,params);
        },
		/**
		 * 商户支付过审
		 * @param params
		 * @returns {*}
		 */
		payOrderApprove(params){
			return httpFetch('post', ` /api/payOrder/payOrderApprove`,params);
		},
		/**
		 * V3商户支付到零钱状态查询
		 * @param params
		 * @returns {*}
		 */
		payOrderCheckV3(params){
			return httpFetch('post', ` /api/payOrder/payOrderCheckV3`,params);
		},
		/**
		 * 批量查单
		 * @param params
		 * @returns {*}
		 */
		batchPayOrderCheck(params){
			return httpFetch('post', ` /api/payOrder/batchPayOrderCheck`,params);
		},
    }
};
export default payOrder;
