/** @format */

import httpFetch from 'src/util/httpFetch';
//题库相关
const pool = {
    name: 'pool',
    apis: {
        /**
         * 宝典题目入库
         * @returns {*}
         */
        // baodiandataJsonPost(params){
        //     return httpFetch('post', `/api/subject/jikaoBaoDianIn`,params);
        // },
        /**
         * 一点通题目入库
         * @returns {*}
         */
        // yiDianTongIn(params){
        //     return httpFetch('post', `/api/subject/yiDianTongIn`,params);
        // },
        /**
         * 题库初始化处理
         * @param params
         * @returns {*}
         */
        initPool(params){
            return httpFetch('post', `/api/pool/initPool`,params);
        },
        /**
         * 调整题目入库后的位置
         * @param params
         * @returns {*}
         */
        subjectPoolEdit(params){
            return httpFetch('post', `/api/pool/subjectPoolEdit`,params);
        },
        /**
         * 调整题目入库后的位置
         * @param params
         * @returns {*}
         */
        subjectsToPools(params){
            return httpFetch('post', `/api/pool/subjectsToPools`,params);
        },
        /**
         * 题库list
         * @param params
         * @returns {*}
         */
        poolList(params){
            return httpFetch('post', `/api/pool/poolList`,params);
        },
        poolDetail(params){
            return httpFetch('post', `/api/pool/poolDetail`,params);
        },
        createPool(params){
            return httpFetch('post', `/api/pool/createPool`,params);
        },
        poolDelete(params){
            return httpFetch('post', `/api/pool/poolDelete`,params);
        },
    }
};
export default pool;
