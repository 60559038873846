/** @format */

import httpFetch from 'src/util/httpFetch';
//考场相关
const exam = {
    name: 'exam',
    apis: {
		/**
		 * 考场列表获取
		 * @param params
		 */
		managerList(params) {
			return httpFetch('post', '/api/exam/managerList', params);
		},
        /**
         * 考场详情
         * @param examId
         * @returns {*}
         */
        examShow(params){
            return httpFetch('post', `/api/exam/examShow`,params);
        },
		/**
		 * 考场基础信息创建编辑
		 * @param params
		 * @returns {*}
		 */
		examCreate(params){
			return httpFetch('post', `/api/exam/examCreate`,params);
		},
		/**
		 * 考场复制
		 * @param params
		 * @returns {*}
		 */
		examCopy(params){
			return httpFetch('post', `/api/exam/examCopy`,params);
		},

		/**
		 * 考场自定义价格设置
		 * @param params
		 * @returns {*}
		 */
		examPriceTemplateEdit(params){
			return httpFetch('post', `/api/exam/examPriceTemplateEdit`,params);
		},
		/**
		 * 考场次要信息编辑
		 * @param params
		 * @returns {Promise<*>}
		 */
		examNormalEdit(params){
			return httpFetch('post', `/api/exam/examNormalEdit`,params);
		},
        /**
         * 地区-
         * @step 科目几
         * @level 地级 province city
         * @code 对应level的code
         * @detail 是否返回详情
         * @returns {*}
         */
        examFilter(params={step:undefined,level:undefined,code:undefined,detail:undefined}) {
            return httpFetch('get', '/api/exam/examFilter',params);
        },

        /**
         * 考场创建
         * @param params
         * @title 标题。
         * @state 视频状态
         * @imageInfo 封面
         * @examInfo 视频
         * @address 地址
         * @step 科目几
         * @examinationRoom 考场
         * @readCount 阅读数
         */
        examPost(
            params = {
                name: '',
                state: undefined,
                address: undefined,
                step: undefined,
            }
        ) {
            return httpFetch('post', '/api/exam', params);
        },
        /**
         * 定制化价格校准
         * @param params
         * @returns {*}
         */
        removeExamCustomize(params) {
            return httpFetch('post', '/api/customize/removeExamCustomize', params);
        },
    }
};
export default exam;
