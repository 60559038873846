import configJs from 'src/Res/config.js'
import * as action from './action'
const {config} = action;
const initState = configJs;
function config1(state = initState,action){
    switch (action.type){
        case config:
            return Object.assign({}, state, action.value);
        default:
            return state;
    }
}
export default config1;
