/** @format */

import httpFetch from 'src/util/httpFetch';
import api from '../App.api';
//resource查询相关
const resource = {
    name: 'resource',
    apis: {
        routeImageGet(params) {
            return httpFetch('get', '/api/resource/routeImageGet', params);
        },
        studentShareImageGet(params) {
            return httpFetch('get', '/api/resource/studentShareImageGet', params);
        },
        /**
         * 删除用户路线图
         * @param params
         * @returns {*}
         */
        removeRouteImage(params) {
            return httpFetch('post', '/api/resource/removeRouteImage', params);
        },
        /**
         * 一键删除考场冗余资源
         * @param params
         * @returns {*}
         */
        redundantResourceRemove(params) {
            return httpFetch('post', '/api/resource/redundantResourceRemove', params);
        },
        /**
         * 一键删除用户图片资源
         * @param params
         * @returns {*}
         */
        userResourceDelete(params) {
            return httpFetch('post', '/api/resource/userResourceDelete', params);
        },
        /**
         * 删除用户旧灯光表资源
         * @param params
         * @returns {*}
         */
        removeOldLightExamImage(params) {
            return httpFetch('post', '/api/resource/removeOldLightExamImage',params);
        }
    }
};
export default resource;
