/*
 * action 类型
 */
export const Enume  = 'Enume';


/*
 * 存储
 */
export function store(value) {

    return { type: Enume, value: {
            data: value,
            loading:false
        } }
}