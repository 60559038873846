/** @format */
import httpFetch from 'src/util/httpFetch';
import webapp from 'src/util/webapp';
const OSS = require('ali-oss');
//上传相关
const upload = {
	name: 'upload',
	apis: {
		/**
		 * 获取上传签名
		 * @returns {*}
		 */
		// signature(param = {fileType:'image'}) {
		//     return httpFetch('get', '/api/upload',param);
		// },
		// /**
		//  * 上传资源到七牛云
		//  * 每次上传前都必须获取最新token
		//  * @param param @fileObj 文件对象
		//  * @returns {PromiseLike<T> | Promise<T> | * | void}
		//  */
		// uploadImage(param = { fileObj:{},fileType:'image' }) {
		//     return upload.apis.signature({fileType:param.fileType}).then((res) => {
		//         const uploadToken = res.data; //{key,token}
		//         const token = uploadToken.token;
		//         const key = uploadToken.key;
		//         let formData = new FormData();
		//         formData.append('token', token);
		//         formData.append('key', key);
		//         formData.append('resource_type', 'image');
		//         formData.append('file', param.fileObj);
		//         return httpFetch('post', 'https://up-z2.qiniup.com', formData);
		//         // return httpFetch('post', config.outsideLink.link, formData);
		//     });
		// },

		/**
		 * 获取阿里云上传凭证
		 * @param params
		 * @returns {*}
		 */
		aliyunToken(params) {
			return httpFetch('get', '/api/upload/aliyunToken', params);
		},
		async putObject(data, fileObj, fileType) {
			const { region, accessKeyId, accessKeySecret, bucket } = data.token;
			const { path, _id } = data.imageInfo;
			const client = new OSS({
				// region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
				region: region,
				secure: true,
				// 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
				accessKeyId: accessKeyId,
				accessKeySecret: accessKeySecret,
				bucket: bucket,
				timeout: 600000
			});
			try {
				// object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
				let result = await client.put(path, fileObj);
				console.log(result);
				return { path, _id };
			} catch (e) {
				console.log(e);
			}
		},
		/**
		 * 上传资源到阿里云
		 * 每次上传前都必须获取最新token
		 * @param param @fileObj 文件对象
		 * @returns {PromiseLike<T> | Promise<T> | * | void}
		 */
		async uploadImage({ fileObj = {}, fileType = 'image', resourceType }) {
			const size = fileObj.size;
			return upload.apis.aliyunToken({ fileType, size, resourceType }).then((res) => {
				return upload.apis.putObject(res.data, fileObj, fileType);
			});
		}
	}
};
export default upload;
