/** @format */

import React, { findDOMNode, Component } from 'react';
// import { string, object, number, array, bool, func, symbol } from 'prop-types';
import style from './css.less';
import classNames from 'classnames';
//每个组件必须写注释，必须更改组件名，且组件名须大写开头，并与文件名一致。
//每个传进来的props必须写defaultProps，且加注释。
//每个props必须加propTypes。
//多余的生命周期方法删除。保持代码整洁性。
//非特殊情况，所有样式必须写在less中，且层级分明。
//所有组件import必须精确到index
//import 必须在所有声明之前。antd的import必须在所有组件之前且less等资源import必须在组件之后,
//以上说明在组件中删除。
export default class Loading extends Component {
    constructor(props) {
        super(props);
    }
    // static propTypes = {
    //
    // };
    // static defaultProps = {
    //
    // };
    // componentWillMount(){
    //
    // }
    // componentDidMount(){
    //
    // }
    // componentWillUpdate(nextProps,nextState){
    //
    // }
    // componentDidUpdate(){
    //
    // }
    // componentWillUnmount(){
    //
    // }
    // componentWillReceiveProps(nextProps){
    // }
    // shouldComponentUpdate(){
    //
    // }
    render() {
        const { error } = this.props;
        if (error) throw error;
        return <div className={style.init}>页面加载中...</div>;
    }
}
