/** @format */

import httpFetch from 'src/util/httpFetch';
//商户支付相关
const offer = {
    name: 'offer',
    apis: {
		//offer列表
		offerList(params){
            return httpFetch('post', `/api/offer/offerList`,params);
        },
		//offer详情
		offerShow(params){
			return httpFetch('post', `/api/offer/offerShow`,params);
		},
		//offer创建
		offerCreate(params){
			return httpFetch('post', `/api/offer/offerCreate`,params);
		},
    }
};
export default offer;
